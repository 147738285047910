// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";

// Authentication
import { getDatabase } from "firebase/database";
import { getStorage } from "firebase/storage";


// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCsu7nOwitgciITMZf52SrabxxXWOr9akA",
  authDomain: "xero-664bc.firebaseapp.com",
  databaseURL: "https://xero-664bc-default-rtdb.firebaseio.com",
  projectId: "xero-664bc",
  storageBucket: "xero-664bc.appspot.com",
  messagingSenderId: "829023880541",
  appId: "1:829023880541:web:5cb7523db35e76b1c114b4",
  measurementId: "G-86FB90Q32F"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const database = getDatabase(app);
export const storage = getStorage(app);