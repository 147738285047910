import React, { useEffect } from "react";
import "../assets/css/about.css";
import AboutImg from "../assets/images/Common.webp";
import WHy1 from "../assets/images/About/Why1.webp";
import WHy2 from "../assets/images/About/Why2.webp";
import Card1 from "../assets/images/Collaboration/About/Collab1.webp";
import Card2 from "../assets/images/Collaboration/About/Collab2.webp";
import Card3 from "../assets/images/Collaboration/About/Collab3.webp";
import Card4 from "../assets/images/Collaboration/About/Collab4.webp";
import BCircle from "../assets/images/blueCircle.png";
import Carosel from "../components/Carosel";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { useLanguage } from "../App";

const About = () => {
  const { currentLanguage, languageChanged } = useLanguage();

  useEffect(() => {
    if (languageChanged) {
      // console.log("Language changed in SayanaOCR component");
    }
  }, [currentLanguage, languageChanged]);
  return (
    <section>
      <div className="content_container">
        <div className="about_heading_container section">
          <h3 className="section__heading">
            Our Mission
          </h3>
          <p className="section__text">
            {currentLanguage === "日本語" ? (
              <>
                Our mission is to redefine industries through human-centered AI
                and computer vision. We innovate, collaborate, and empower
                businesses, driving a future where technology and human
                potential converge seamlessly for limitless possibilities
              </>
            ) : (
              <>
                私たちの使命は、人間中心の AI とコンピューター
                ビジョンを通じて産業を再定義することです。私たちはビジネスを革新し、協力し、力を与え、テクノロジーと人間の可能性がシームレスに融合して無限の可能性をもたらす未来を推進します。
              </>
            )}
          </p>
        </div>

        <div className="about_product-container section">
          <h1 className="section__heading">
            Drive Transformation and Shaping Boundless Future through Seamless
            Technologies Integration
          </h1>
          <img src={AboutImg} alt="" />
          {/* <button className="btn">Explore Our Products</button> */}
        </div>

        <div className="sayanaAi-container section">
          <div className="section_heading-container">
            <h1 className="section__heading">What is Sayana AI</h1>
            <h4 className="section__subheading">
              {currentLanguage === "日本語" ? (
                <>
                  Sayana AI is an advanced platform developed by RAWATTECH that
                  harnesses the power of artificial intelligence and computer
                  vision
                </>
              ) : (
                <>
                  サヤナAIは、人工知能とコンピュータビジョンの力を活用するローワテックが開発した先進的なプラットフォームです。
                </>
              )}
            </h4>
            <h4 className="section__text">
              {currentLanguage === "日本語" ? (
                <>
                  It's designed to provide innovative solutions for businesses,
                  offering tailored AI services that streamline processes,
                  enhance efficiency, and drive growth. Essentially, Sayana AI
                  leverages cutting-edge technology to solve complex problems
                  and empower businesses to achieve more
                </>
              ) : (
                <>
                  企業向けに革新的なソリューションを提供することを目的としており、プロセスを合理化し、効率を高め、成長を促進するオーダーメイドのAIサービスを提供している。基本的に、サヤナAIは最先端のテクノロジーを活用して複雑な問題を解決し、企業がより多くのことを達成できるようにします。
                </>
              )}
            </h4>
            <Link to="/contact" className="btn_link">
              <button className="btn">
                {currentLanguage === "日本語" ? "Get Started" : "始めましょう"}
              </button>
            </Link>
          </div>

          <div className="why_us-content_container">
            <div className="content__container">
              <img src={WHy1} className="content_img" alt="" />
              <div className="about_content_text">
                <h1 className="container__heading">
                  {currentLanguage === "日本語"
                    ? "Optimize Your AI Workflow with Sayana AI"
                    : "Sayana AIでAIワークフローを最適化する"}
                </h1>
                <p className="container__text">
                  {currentLanguage === "日本語" ? (
                    <>
                      Leveraging advanced automation, Sayana AI optimizes
                      processes, reducing manual effort and time, thereby
                      enhancing overall operational efficiency for businesses
                    </>
                  ) : (
                    <>
                      高度な自動化を活用して、Sayana
                      AIはプロセスを最適化し、手作業の労力と時間を削減することで、企業の全体的な業務効率を高めます。
                    </>
                  )}
                </p>
              </div>
            </div>
            <div className="content__container">
              <div className="about_content_text">
                <h1 className="container__heading">
                  {currentLanguage === "日本語"
                    ? "Bring the power of AI to your workflows"
                    : "ワークフローにAIの力を"}
                </h1>
                <p className="container__text">
                  {currentLanguage === "日本語" ? (
                    <>
                      Sayana AI offers customized AI solutions crafted to
                      address specific business needs, providing precise and
                      effective tools to streamline operations and drive growth
                    </>
                  ) : (
                    <>
                      Sayana
                      AIは、特定のビジネスニーズに対応するためにカスタマイズされたAIソリューションを提供し、業務を合理化し、成長を促進するための正確で効果的なツールを提供します。
                    </>
                  )}
                </p>
              </div>
              <img src={WHy2} className="content_img" alt="" />
            </div>
          </div>
        </div>

        <div className="teams_container section">
          <h1 className="section__heading">Team</h1>
          <Carosel />
        </div>

        <div className="our_values_container section">
          <div className="section_heading-container">
            <h1 className="section__heading">Our Values</h1>
            <p className="section__subheading">
              {currentLanguage === "日本語" ? (
                <>
                  Accelerate AI adoption and reusability across the enterprise
                </>
              ) : (
                <>企業全体でAIの導入と再利用を加速する</>
              )}
            </p>
            <p className="section__text">
              {currentLanguage === "日本語" ? (
                <>
                  To truly harness the power of AI, enterprises need to nurture
                  a data-centric culture, foster an environment of innovation,
                  and invest in the right talent and infrastructure
                </>
              ) : (
                <>
                  AIの力を真に活用するために、企業はデータ中心の文化を育み、イノベーションの環境を醸成し、適切な人材とインフラに投資する必要がある。
                </>
              )}
            </p>
          </div>
          <div className="coloboration_cards_container">
            <div className="coloboration_card">
              <img src={Card1} alt="" className="card_img" />
              <h3 className="card_heading">
                {currentLanguage === "日本語" ? "Innovation" : "イノベーション"}
              </h3>
              <p className="container__text">
                {currentLanguage === "日本語" ? (
                  <>
                    We believe in pushing the boundaries of technology to unlock
                    new possibilities
                  </>
                ) : (
                  <>
                    私たちは、テクノロジーの限界を押し広げ、新たな可能性を解き放つことを信じている。
                  </>
                )}
              </p>
            </div>
            <div className="coloboration_card">
              <img src={Card2} alt="" className="card_img" />
              <h3 className="card_heading">
                {currentLanguage === "日本語"
                  ? "Customer-Centric"
                  : "顧客中心主義"}
              </h3>
              <p className="container__text">
                {currentLanguage === "日本語" ? (
                  <>
                    We prioritize understanding and meeting the unique needs of
                    our customers
                  </>
                ) : (
                  <>顧客独自のニーズを理解し、それに応えることを優先する</>
                )}
              </p>
            </div>
            <div className="coloboration_card">
              <img src={Card3} alt="" className="card_img" />
              <h3 className="card_heading">
                {currentLanguage === "日本語"
                  ? "Quality & Accuracy"
                  : "品質と精度"}
              </h3>
              <p className="container__text">
                {currentLanguage === "日本語" ? (
                  <>
                    We uphold a relentless commitment to precision in every
                    solution we deliver
                  </>
                ) : (
                  <>
                    私たちは、提供するすべてのソリューションにおいて、正確さへの絶え間ないコミットメントを堅持します。
                  </>
                )}
              </p>
            </div>
            <div className="coloboration_card">
              <img src={Card4} alt="" className="card_img" />
              <h3 className="card_heading">
                {currentLanguage === "日本語"
                  ? "Collaboration"
                  : "コラボレーション"}
              </h3>
              <p className="container__text">
                {currentLanguage === "日本語" ? (
                  <>
                    We embrace diverse perspectives and foster strong
                    partnerships to achieve collective success
                  </>
                ) : (
                  <>
                    多様な視点を受け入れ、強力なパートナーシップを育むことで、集団的な成功を実現する。
                  </>
                )}
              </p>
            </div>
          </div>
        </div>

        {/* <div className="free_trial section">
          <div className="free_trail_container">
            <div className="free_trial-content">
              <h2 className="container__heading">Try Free 14 day trial</h2>

              <p className="conatiner__text">
                Try Sayana AI for free, or get in touch with an expert <br /> for a
                custom demo of the platform
              </p>

              <button className="btn">Get Stated - try free</button>
            </div>
            <img className="free_trail-img" src={BCircle} alt="" />
          </div>
        </div> */}
      </div>
    </section>
  );
};

export default About;
