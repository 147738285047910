import React, { useState, useEffect } from "react";
import Team1 from "../assets/images/Team/Team1.svg";
import Team2 from "../assets/images/Team/Team2.svg";

const Carosel = () => {
  const [current, setCurrent] = useState(0);
  const [autoplayIntervalId, setAutoplayIntervalId] = useState();
  const images = [Team1, Team2];

  const nextImage = () => {
    if (current < images.length - 1) {
      let newIndex = current + 1;
      updateImageSlides(newIndex, "left");
      setCurrent(newIndex);
    }
  };

  const prevImage = () => {
    if (current > 0) {
      let newIndex = current - 1;
      updateImageSlides(newIndex, "right");
      setCurrent(newIndex);
    }
  };

  const jumpImage = (e) => {
    let jumpIndex = parseInt(e.target.id);
    if (jumpIndex === current) return;
    let direction = jumpIndex - current >= 0 ? "left" : "right";
    updateImageSlides(jumpIndex, direction);
  };

  const updateImageSlides = (index, direction) => {
    const newSlides = [...imageSlides];
    newSlides[current] = `slide-image ${direction}Out`;
    newSlides[index] = `slide-image ${direction}In`;
    setImageSlides(newSlides);
    updateBubbles(index);
    setCurrent(index);
  };

  const updateBubbles = (highlight) => {
    const newBubbles = bubbles.map((bubble, index) => ({
      ...bubble,
      style: {
        borderColor: index === highlight ? "white" : "black",
      },
    }));
    setBubbles(newBubbles);
  };

  useEffect(() => {
    updateBubbles(current);
  }, [current]);

  const [imageSlides, setImageSlides] = useState(
    images.map((_, index) =>
      index === 0 ? `slide-image leftIn` : `slide-image rightOut`
    )
  );
  const [bubbles, setBubbles] = useState(
    Array(images.length)
      .fill(null)
      .map(() => ({ style: {} }))
  );

  return (
    <>
      <div className="gallery-container">
        <div className="gallery-track">
          {images.map((image, index) => (
            <div
              key={index}
              className={imageSlides[index]}
              style={{
                backgroundImage: `url(${image})`,
                transform:
                  index === current ? "translateX(0%)" : "translateX(100%)",
                  width: "100%"
              }}
            ></div>
          ))}
        </div>
      </div>
      <div className="sliding__btns">
        <span
          className="button-prev"
          onClick={prevImage}
          disabled={current === 0}
        >
          chevron_left
        </span>
        <span
          className="button-next"
          onClick={nextImage}
          disabled={current === images.length - 1}
        >
          chevron_right
        </span>
      </div>
    </>
  );
};

export default Carosel;
