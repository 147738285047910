import React from "react";
import Logo from "../assets/images/Logo.svg";
import "../assets/css/footer.css";
import Phone from "../assets/images/phone.svg";
import Mail from "../assets/images/mail.svg";
import Arrow from "../assets/images/arrow.svg";
import L1 from "../assets/images/footer/tokyo.svg";
import L2 from "../assets/images/footer/jaipur.svg";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const Footer = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <section className="footer_section section">
      <div className="footer">
        <div className="company_name">
          <div className="logo_content">
            <img src={Logo} alt="" />
            {/* <p className="footer_desc">
              Drive your business 10x faster with AI and computer vision
              technologies
            </p> */}
          </div>
          <div className="footer_addr_container">
            <p className="addr">
              <img src={L1} alt="" />
              <p>

             <b>Japan,</b> Tokyo, Koto-Ku, Ariake, 3-7-26, Ariake Frontier Building Tower B, Level 9
              </p>
            </p>
            <p className="addr">
            <img src={L2} alt="" />
            <p>

            STPI, IT park EPIP, Sitapura, Jaipur, Rajasthan 302013, <b>India</b>
            </p>
            </p>
          </div>
        </div>

        <div className="footer_links">
          <h5 className="links_heading">Quick Links</h5>
          <ul className="footer_links_container">
            <Link className="footer_Link" to="/about" onClick={scrollToTop}>
              <li className="footer_link">About</li>
            </Link>
            <Link className="footer_Link" to="/solutions" onClick={scrollToTop}>
              <li className="footer_link">Solutions</li>
            </Link>
            {/* <li className="footer_link">Pricing Plans</li> */}
            {/* <li className="footer_link">Blogs</li> */}
            <Link className="footer_Link" to="/contact" onClick={scrollToTop}>
              <li className="footer_link">Contact us</li>
            </Link>
          </ul>
        </div>

        <div className="footer_links">
          <h5 className="links_heading">Products</h5>
          <ul className="footer_links_container">
            <Link className="footer_Link" to="/ocr" onClick={scrollToTop}>
              <li className="footer_link">OCR</li>
            </Link>
            
            <Link className="footer_Link" to="/idp" onClick={scrollToTop}>
              <li className="footer_link">IDP</li>
            </Link>

            <Link className="footer_Link" to="/odc" onClick={scrollToTop}>
              <li className="footer_link">ODC</li>
            </Link>

            <Link className="footer_Link" to="/connect" onClick={scrollToTop}>
              <li className="footer_link">Connect</li>
            </Link>

            <Link
              className="footer_Link"
              to="/expression"
              onClick={scrollToTop}
            >
              <li className="footer_link">Expression</li>
            </Link>
           
          </ul>
        </div>

        <div className="footer_links">
          {/* <div className="footer_free_trail-container">
            <div className="footer_free_trail">
              <h6 className="free_text">Free 14-day trial</h6>
              <button className="btn2">Register for free</button>
            </div>
          </div> */}
          <h5 className="links_heading">
            {/* <img src={Arrow} alt="" />  */}
            Contact Us
          </h5>
          <div className="footer_links_container">
            <p className="contact__details">
              <img src={Phone} alt="" />
              <p>+81 70 8456 2424, <br /> +91 92563 09008</p>
            </p>
            <p className="contact__details">
              <img src={Mail} alt="" />
              <p>bds@rawattech.com, rtb@rawattech.com</p>
            </p>
          </div>
        </div>
      </div>
      <p className="copyright">
        Copyright © 2023 <strong className="com_name">Rawattech</strong>
      </p>
    </section>
  );
};

export default Footer;
