import React, { useState, useEffect, useRef } from "react";
import "../assets/css/Chatbot.css";
import Bot from "../assets/images/Bot.png";
import BotIcon from "../assets/images/BotIcon.png";
import UserIcon from "../assets/images/Person.svg";
import CloseIcon from "../assets/images/Close.svg";
import SendIcon from "../assets/images/Send.svg";
import { onValue, ref } from "firebase/database";
import { database } from "../firebase.config";

const Chatbot = () => {
  const [messages, setMessages] = useState([]);
  const [inputMsg, setInputMsg] = useState("");
  const [isBotOpen, setIsBotOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [URL, setURL] = useState();

  const messagesContainerRef = useRef(null);

  useEffect(() => {
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTop =
        messagesContainerRef.current.scrollHeight;
    }
  }, [messages]);

  const handleInputChange = (event) => {
    setInputMsg(event.target.value);
  };

  const handleIconClick = async () => {
    await getURL();
    setIsBotOpen(true);
    setMessages((prevMessages) => [
      ...prevMessages,
      { text: "Hi, how can I help you?", isUser: false },
    ]);
  };

  const handleCloseClick = () => {
    setIsBotOpen(false);
    setMessages([]);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    const mainval = inputMsg;

    // Message processing logic
    const newMessage = {
      text: mainval,
      isUser: true,
    };

    setMessages((prevMessages) => [...prevMessages, newMessage]);
    setInputMsg("");

    // Prepare the API request data
    const apiUrl = URL;
    // const apiUrl =
    //   "https://b8dd-122-176-148-236.ngrok-free.app/api/aibot/v1/aibot_handler";
    const requestData = {
      chat_id: "123",
      chat_query: mainval,
    };
    const botResponse = [];
    const message =
      "I'm currently unavailable due to scheduled maintenance. I'll be back up and running soon. Please try contacting us again later by email at contact bds@rawattech.com if the issue persists.";
    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      });

      if (!response.ok) {
        botResponse.push({
          text: message,
          isUser: false,
        });
      }

      const responseData = await response.json();

      if (typeof responseData.Response === "string") {
        botResponse.push({
          text: responseData.Response,
          isUser: false,
        });
      } else if (typeof responseData.Response === "object") {
        const results = responseData.Response;
        const resultKeys = Object.keys(results);

        resultKeys.forEach((resultKey) => {
          const result = results[resultKey];
          if (result.Title && result.URL) {
            botResponse.push({
              title: result.Title,
              url: result.URL,
            });
          } else {
            botResponse.push({
              text: resultKey,
              isUser: false,
            });
          }
        });
      }

      setMessages((prevMessages) => [...prevMessages, ...botResponse]);
      setIsLoading(false);
    } catch (error) {
      console.error("Error making API request:", error);
      botResponse.push({
        text: message,
        isUser: false,
      });
      setMessages((prevMessages) => [...prevMessages, ...botResponse]);
      setIsLoading(false);
    }
  };

  const getURL = async () => {
    try {
      const urlRef = ref(database, "Xero/Bot/url");
      onValue(urlRef, (snapshot) => {
        if (snapshot.exists()) {
          const url = snapshot.val();
          setURL(url);
        }
      });
    } catch (error) {
      console.log("err", error);
    }
  };

  return (
    <div className={`botIcon ${isBotOpen ? "showBotSubject" : ""}`}>
      <div className="botIconContainer">
        <div className="iconInner" onClick={handleIconClick}>
          <img className="bot_img" src={Bot} alt="" />
        </div>
      </div>
      <div className="Layout Layout-open Layout-expand Layout-right">
        <div className="Messenger_messenger">
          <div className="Messenger_header">
            <h4 className="Messenger_prompt">RAWATTECH Bot</h4>
            <span className="chat_close_icon" onClick={handleCloseClick}>
              <img src={CloseIcon} alt="" />
            </span>
          </div>
          <div className="Messenger_content">
            <div className="Messages" ref={messagesContainerRef}>
              <div className="Messages_list">
                {messages.map((message, index) => (
                  <div
                    key={index}
                    className={`msg ${message.isUser ? "user" : "bot"}`}
                  >
                    <span className="avtr">
                      <img
                        // className="bot_img"
                        className={message.isUser ? "user_img" : "bot_img"}
                        src={message.isUser ? UserIcon : BotIcon}
                        alt=""
                      />
                    </span>
                    {/* Render if message has Query_Response */}
                    <span className="responsText">
                      {message.text && <div>{message.text}</div>}
                    </span>
                  </div>
                ))}
                {isLoading && (
                  <div className="bot-message">
                    <div className="typing-indicator">
                      <span></span>
                      <span></span>
                      <span></span>
                    </div>
                  </div>
                )}
              </div>
            </div>
            {isBotOpen && (
              <form id="messenger" onSubmit={handleSubmit}>
                <div className="Input Input-blank">
                  <input
                    name="msg"
                    className="Input_field"
                    placeholder="Send a message..."
                    value={inputMsg}
                    onChange={handleInputChange}
                    required
                  />
                  <button
                    type="submit"
                    className="Input_button Input_button-send"
                  >
                    <div className="Icon">
                      <img src={SendIcon} alt="" />
                    </div>
                  </button>
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Chatbot;
