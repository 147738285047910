import React, { useEffect, useReducer, createContext, useContext, lazy, Suspense } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useLocation,
} from "react-router-dom";
import "./App.css";
import Navbar from "./components/Navbar";
import About from "./pages/About";
import Footer from "./components/Footer";
import Chatbot from "./components/Chatbot";

// Lazy Loading
const Register = lazy(() => import("./pages/Register"));
const Home = lazy(() => import("./pages/Home"));
const SayanaOCR = lazy(() => import("./pages/SayanaOCR"));
const SayanaIDP = lazy(() => import("./pages/SayanaIDP"));
const SayanaODC = lazy(() => import("./pages/SayanaODC"));
const SayanaConnect = lazy(() => import("./pages/SayanaConnect"));
const SayanaExpression = lazy(() => import("./pages/SayanaExpression"));
const Contactus = lazy(() => import("./pages/Contactus"));
const Solutions = lazy(() => import("./pages/Solutions"));

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

const LanguageContext = createContext();

// Your language reducer and initial state
const languageReducer = (state, action) => {
  switch (action.type) {
    case "TOGGLE_LANGUAGE":
      const newLanguage = state.currentLanguage === "日本語" ? "Eng" : "日本語";
      localStorage.setItem("language", newLanguage);
      return {
        ...state,
        currentLanguage: newLanguage,
        languageChanged: true,
      };
    case "RESET_LANGUAGE_CHANGED":
      return {
        ...state,
        languageChanged: false,
      };
    default:
      return state;
  }
};

const initialState = {
  currentLanguage: localStorage.getItem("language") || "日本語",
  languageChanged: false,
};

function App() {
  const [state, dispatch] = useReducer(languageReducer, initialState);

  useEffect(() => {
    if (state.languageChanged) {
      console.log("Language changed in App component");
      dispatch({ type: "RESET_LANGUAGE_CHANGED" }); // Reset the flag
    }
  }, [state.languageChanged]);

  const languageContextValue = {
    currentLanguage: state.currentLanguage,
    toggleLanguage: () => dispatch({ type: "TOGGLE_LANGUAGE" }),
    languageChanged: state.languageChanged,
  };

// Restrict user to open Inspect
  useEffect(() => {
    const handleContextMenu = (event) => {
      event.preventDefault();
    };

    const handleKeyDown = (event) => {
      if (event.ctrlKey && event.shiftKey && event.key === "I") {
        event.preventDefault();
      }
    };

    window.addEventListener("contextmenu", handleContextMenu);
    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("contextmenu", handleContextMenu);
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <Router>
      <LanguageContext.Provider value={languageContextValue}>
        <div>
          <Navbar />
          <Chatbot />
          <div className="main_container">
            <ScrollToTop />
            <Suspense fallback={<div className="loader_container">
              <div className="custom-loader"></div>
            </div>}>
            <Switch>
              <Route path="/" exact component={Home} />
              <Route path="/register" component={Register} />
              <Route path="/solutions" component={Solutions} />
              <Route path="/about" component={About} />
              <Route path="/ocr" component={SayanaOCR} />
              <Route path="/odc" component={SayanaODC} />
              <Route path="/idp" component={SayanaIDP} />
              <Route path="/connect" component={SayanaConnect} />
              <Route path="/expression" component={SayanaExpression} />
              <Route path="/contact" component={Contactus} />
            </Switch>
            </Suspense>
            <Footer />
          </div>
        </div>
      </LanguageContext.Provider>
    </Router>
  );
}

export const useLanguage = () => useContext(LanguageContext);

export default App;
