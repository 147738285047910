import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import Logo from "../assets/images/Logo.svg";
import Home from "../assets/images/navbar/Home.svg";
import About from "../assets/images/navbar/About.svg";
import Products from "../assets/images/navbar/Products.svg";
import Contact from "../assets/images/navbar/Contact.svg";
import Lang from "../assets/images/navbar/Language.svg";
import upArrow from "../assets/images/navbar/ChevronUp.png";
import downArrow from "../assets/images/navbar/ChevronDown.png";
import "../assets/css/navbar.css";
import { useLanguage } from "../App";
import Solution from '../assets/images/navbar/Solutions.svg'

const Navbar = () => {
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [isNavOpen, setNavOpen] = useState(false);

  // Reference Variable
  const dropdownRef = useRef(null);

  const handleDropdownToggle = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  const handleLinkClick = () => {
    if (window.innerWidth <= 768) {
      setNavOpen(false);
    }
    setDropdownOpen(false);
  };

  const handleNavToggle = () => {
    setNavOpen(!isNavOpen);
  };

  // Close the navbar when the window is resized
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 768) {
        setNavOpen(false);
      }
    };

    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };

    window.addEventListener("click", handleClickOutside);

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("click", handleClickOutside);
    };
  }, []);

  // Handle language change
  const { currentLanguage, toggleLanguage } = useLanguage();

  const handleToggleLanguage = () => {
    toggleLanguage();
    handleLinkClick();
  };

  return (
    <div className="navbar">
      <nav className="navbar_nav">
        <Link className="" to="/" onClick={handleLinkClick}>
          <img className="nav_logo" src={Logo} alt="" />
        </Link>
        <div className={`links_container ${isNavOpen ? "open" : ""}`}>
          <div
            className={`menu-icon ${isNavOpen ? "open" : ""}`}
            onClick={handleNavToggle}
          >
            <div className="bar"></div>
            <div className="bar"></div>
            <div className="bar"></div>
          </div>
          <ul className={`nav_navlist ${isNavOpen ? "show" : ""}`}>
            <Link to="/" className="nav_links" onClick={handleLinkClick}>
              <img className="nav_link_img" src={Home} alt="" />
              {currentLanguage === "日本語" ? <>Home</> : <>ホーム</>}
            </Link>

            <Link to="/about" className="nav_links" onClick={handleLinkClick}>
              <img className="nav_link_img" src={About} alt="" />
              {currentLanguage === "日本語" ? <>About</> : <>について</>}
            </Link>

            <Link
              to="/solutions"
              className="nav_links"
              onClick={handleLinkClick}
            >
              <img className="nav_link_img" src={Solution} alt="" />
              {currentLanguage === "日本語" ? (
                <>Solutions</>
              ) : (
                <>ソリューション</>
              )}
            </Link>
            
            <li
              className={`nav_links nav_dropdown ${
                isDropdownOpen ? "dropdown_active" : ""
              }`}
              onClick={handleDropdownToggle}
              ref={dropdownRef}
            >
              <p className="nav_link">
                <img className="nav_link_img" src={Products} alt="" />
                {currentLanguage === "日本語" ? <>Products</> : <>製品紹介</>}
                <img className="up_down_arrows" src={isDropdownOpen ? upArrow : downArrow} alt="" />
              </p>
              {isDropdownOpen && (
                <ul className="dropdown_content">
                  <Link
                    className="nav_links"
                    to="/ocr"
                    onClick={handleLinkClick}
                  >
                    <li>
                      {currentLanguage === "日本語" ? "Sayana " : "サヤナ "} OCR
                    </li>
                  </Link>

                  <Link
                    className="nav_links"
                    to="/idp"
                    onClick={handleLinkClick}
                  >
                    <li>
                      {currentLanguage === "日本語" ? "Sayana " : "サヤナ "} IDP
                    </li>
                  </Link>

                  <Link
                    className="nav_links"
                    to="/odc"
                    onClick={handleLinkClick}
                  >
                    <li>
                      {currentLanguage === "日本語" ? "Sayana " : "サヤナ "} ODC
                    </li>
                  </Link>

                  <Link
                    className="nav_links"
                    to="/connect"
                    onClick={handleLinkClick}
                  >
                    <li>
                      {currentLanguage === "日本語" ? "Sayana " : "サヤナ "}{" "}
                      Connect
                    </li>
                  </Link>

                  <Link
                    className="nav_links"
                    to="/expression"
                    onClick={handleLinkClick}
                  >
                    <li>
                      {currentLanguage === "日本語" ? "Sayana " : "サヤナ "}{" "}
                      Expression
                    </li>
                  </Link>
                </ul>
              )}
            </li>
            <Link to="/contact" className="nav_links" onClick={handleLinkClick}>
              <img className="nav_link_img" src={Contact} alt="" />
              {currentLanguage === "日本語" ? (
                <>Contact Us</>
              ) : (
                <>お問い合わせ</>
              )}
            </Link>
            <button onClick={handleToggleLanguage} className="lang_btn">
              <img className="nav_link_img" src={Lang} alt="" />
              {currentLanguage}
            </button>
          </ul>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
